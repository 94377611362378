.action_item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 8px;
  color: #5e7285;
  font-weight: 500;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;

  &[data-visible='true'] {
    opacity: 1;
    pointer-events: all;
  }
  &[data-with-left-divider='true'] {
    border-left: 1px solid #d7dee4;
  }

  &:hover {
    cursor: pointer;
  }

  svg path {
    stroke: #5e7285;
  }
  > div {
    white-space: nowrap;
  }
}

.tooltip {
  & > div {
    margin: 0 !important;
    padding: 8px !important;
    max-width: 375px;
  }
}

.enabled_without_visual_effect {
  &:hover {
    color: #437bff;

    svg path {
      stroke: #437bff;
    }
  }
}

.action_item.blue {
  color: #0851fe;
  svg path {
    stroke: #0851fe;
  }
  &:hover {
    color: #437bff;
    svg path {
      stroke: #437bff;
    }
  }
}

.action_item.red {
  color: #fc3030;
  svg path {
    stroke: #fc3030;
  }
  &:hover {
    color: #fe6464;
    svg path {
      stroke: #fe6464;
    }
  }
}
