.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.multi_input_container {
  padding: 16px 16px 0;
}

.multi_input {
  align-items: center;
}

.separator_icon {
  min-width: 18px;
  transform: rotate(180deg) translateY(-1px);
}

.date_picker {
  min-width: unset;
  padding-bottom: 16px;
  :global(.MuiDayCalendar-slideTransition) {
    min-height: 252px;
  }
  :global(.MuiDateCalendar-root) {
    height: unset;
    width: unset;
  }
  :global(.MuiDayCalendar-weekDayLabel) {
    color: #141414;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }
  :global(.MuiPickersDay-root) {
    font-size: 14px;
    &:focus {
      background-color: #437bff;
      color: #fff;
    }
  }
  :global(.Mui-selected) {
    background-color: #437bff;
    color: #fff;
    &:hover {
      background-color: #437bff;
    }
  }
  :global(.MuiPickersArrowSwitcher-root) > span {
    color: #141414;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  }
  :global(.MuiDateRangePickerDay-root) {
    border-radius: unset;
  }
  :global(.MuiDateRangePickerDay-rangeIntervalDayHighlight) {
    background: rgba(234, 238, 252, 0.7);
  }

  .date_label {
    color: #141414;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
  }
  .date_button {
    width: 29px;
    padding: 4px;
    border-radius: 5px;
    background: #dae3ff;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
}

.predefined_options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px 0 8px;
  border-top: 1px solid #e7edf1;
}

.predefined_button {
  min-height: 34px;
  padding: 0 8px;
  border-radius: 8px;
  background-color: #f8f9fb;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 150%;
  transition: all 200ms;

  &[data-isActive='true'] {
    background-color: #eaeefc;
  }
}
