.assignable_container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  &:hover + .delete_assigned_button,
  .delete_assigned_button:hover {
    display: flex;
  }
}

.selected_user_container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 38px);
}

.selected_user_name {
  font-weight: 500;
  line-height: 1.2;
}

.spinner_container {
  position: relative;
  display: flex;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #d7eeff;
}

.avatar_circle {
  cursor: pointer;
}

.user_info_container {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  .margin_right {
    margin-right: 4px;
  }
}
