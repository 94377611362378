.container {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
}

.ellipsis {
  flex: 1;
  align-self: flex-start;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.task_description {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  max-width: 650px;
  max-height: 300px;
  padding: 10px 16px;

  ul,
  ol {
    padding-left: 40px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    margin-block: 16px;
  }

  a {
    display: inline;
  }
}

.eye {
  display: none;
  padding: 6px;
  margin-top: -4px;
  border-radius: 8px;
  transition: all 200ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.container:hover .eye {
  display: flex;
}
