.container {
  display: flex;
  gap: 3px;

  svg {
    width: 16px !important;
    height: 16px !important;
    path {
      stroke: #141414;
    }
  }
}

.action_button {
  border-radius: 4px !important;
  transition: background-color 200ms !important;
  box-shadow: 0px 5px 24px rgba(0, 0, 0, 0.08) !important;
  border: 1px solid #e4e4e4 !important;
  &:hover {
    background-color: #ebebeb !important;
  }
  &[data-disabled='true'] {
    background-color: rgba(210, 210, 210, 0.4);
    backdrop-filter: blur(3px);
  }
}
