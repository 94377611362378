@import '../../styles/vars.scss';

// Mixins
@mixin base($background, $content) {
  background-color: $background;
  border-color: $background;
  svg * {
    stroke: $content;
  }
  .text {
    &,
    * {
      color: $content;

      @media (max-width: $tablet) {
        font-size: 13px;
      }

      @media (max-width: $mobile) {
        font-size: 12px;
      }
    }
  }
}
@mixin hover($background, $content) {
  &.pressable {
    cursor: pointer;
    &:hover {
      border-color: $background;
      background-color: $background;
      svg * {
        stroke: $content;
      }
      .text {
        &,
        * {
          color: $content;
        }
      }
    }
  }
}

@mixin active($background, $content) {
  &.active {
    border-color: $background;
    background-color: $background;
    svg * {
      stroke: $content;
    }
    .text {
      &,
      * {
        color: $content;
      }
    }
  }
}
@mixin disabled() {
  &.disabled {
    opacity: 0.7;
    cursor: default;
  }
}

// Main
.container {
  padding: 4px 12px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #878b92;
  color: #878b92;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  display: flex;
  transition: opacity 0.2s;
  align-items: center;
  gap: 4px;

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.light_blue {
    @include base(#e8f4ff, #677a8c);
    @include hover(#c1dbf4, #677a8c);
    @include active(#89b8e3, #fff);
    @include disabled();
  }
  &.blue {
    @include base(#7894ff, #fff);
    @include hover(#6383fd, #fff);
    @include active(#437bff, #fff);
    @include disabled();
  }
  &.green {
    @include base(#c3df50, #465214);
    @include hover(#b7d63b, #465214);
    @include active(#a7c91c, #fff);
    @include disabled();
  }
  &.light_green {
    @include base(#e8f5b3, #5d663a);
    @include hover(#dbec97, #5d663a);
    @include active(#cfe37e, #5d663a);
    @include disabled();
  }
  &.dark_orange {
    @include base(#ffe3d9, #e9774d);
    @include hover(#f8a586, #fff);
    @include active(#f48c65, #ffffff);
    @include disabled();
  }
  &.red {
    @include base(#ffd7d7, #fc3030);
    @include hover(#ff7d7d, #fff);
    @include active(#ff6464, #fff);
    @include disabled();
  }
  &.deep_green {
    @include base(#bcf4de, #2bab77);
    @include hover(#3ed296, #fff);
    @include active(#1acb83, #fff);
    @include disabled();
  }
  // &.light_orange {
  //   @include base(#f8bc86, #df8839);
  //   @include hover(#ffe5cd, #f8bc86, #df8839);
  //   @include active(#ffead7, #df8839);
  //   @include disabled();
  // }
}
